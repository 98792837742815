import {
	Box,
	Chip,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	styled,
	tableCellClasses
} from '@mui/material';
import Colors from '../../assets/color';
import { outcomesTableData } from '../../assets/constants';
import SlideInAnimation from '../framerAnimations/SlideInAnimation';
import FadeUpAnimation from '../framerAnimations/FadeupAnimation';

const OutComesSection = () => {

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.common.black,
			color: theme.palette.common.white
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 16,
			fontWeight: '16px',
			color: '#000000D1',
			padding: '12px 0'
		}
	}));

	return (
		<Box>
			<SlideInAnimation>
				<Typography textAlign="center" className="uppercase text-lg" color={Colors.accent1} sx={{fontSize: {xs: 20, fontFamily: 'Aspira Semibold'}}}>
					On the road to success
				</Typography>
			</SlideInAnimation>
			<Box sx={{ my: 2 }}>
			<SlideInAnimation delay={0.4}>
				<Typography  textAlign="center" sx={{ fontWeight: 700, fontSize: {xs: 30}, mx:'auto', textAlign: 'center' }} className="text-3xl is-aspira-700">
					“A career extends well beyond that first big job offer...”
				</Typography>
			</SlideInAnimation>
			</Box>
			
			<Box sx={{ width: { xs: '100%', sm: '80%' }, mx: 'auto', my: 2, lineHeight: {sm:'1.6'} }}>
			<SlideInAnimation delay={0.5}>
				<Box fontSize={{xs: 14, sm: 18}} className='is-aspira-600' sx={{ color: Colors.textDark3}}>
				As automation and Al continue to advance, industries are recalibrating their priorities, ushering in a transformation in workforce dynamics. 
				Roles such as data engineering, cloud engineering, and Python development are becoming increasingly indispensable. 
				Employers are seeking individuals proficient in these domains to harness the power of data, optimize processes, and develop innovative solutions. 
				These positions not only require technical expertise but also demand adaptability and creativity in navigating the ever-evolving landscape of technology. 
				As traditional job roles evolve or become automated, the demand for these specialized skill sets is expected to surge, 
				shaping the future of work across various sectors.
				</Box>
			</SlideInAnimation>
			</Box>

			{/* table */}
			<FadeUpAnimation delayTime={0.6}>
				<TableContainer component={Paper} sx={{ borderRadius: '15px', my: 6, backgroundColor: Colors.cardTransparentBg,
					boxShadow: '20px 32px 42px 0px #00000014'
				 }} className="rounded-lg">
					<Table sx={{ minWidth: 650, color: 'red' }} aria-label="simple table" className="customTable rounded-xl">
						<TableHead>
							<TableRow>
								<TableCell
									color={Colors.textDark2}
									colSpan={4}
									align="center"
									sx={{ fontSize: '30px', py: 2 }}
									className="is-aspira-600"
								>
									We’re driven by outcomes
								</TableCell>
							</TableRow>
						</TableHead>
						<TableHead>
							<TableRow>
								<TableCell
									className="is-aspira-600"
									color={Colors.textDark2}
									sx={{ fontWeight: 700, fontSize: '18px', padding: '12px' }}
								></TableCell>
								<TableCell
									className="is-aspira-600"
									color={Colors.textDark2}
									align="center"
									sx={{ fontWeight: 700, fontSize: '18px', padding: '12px' }}
								>
									Employment Rate
								</TableCell>
								<TableCell
									className="is-aspira-600"
									color={Colors.textDark2}
									align="center"
									sx={{ fontWeight: 700, fontSize: '18px', padding: '12px' }}
								>
									Median Salary
								</TableCell>
								<TableCell
									className="is-aspira-600"
									color={Colors.textDark2}
									align="center"
									sx={{ fontWeight: 700, fontSize: '18px', padding: '12px' }}
								>
									Hired Within
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{outcomesTableData.map((row, i) => (
								<TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<StyledTableCell
										className="is-aspira-600"
										sx={{ fontSize: '16px', fontWeight: 600 }}
										component="td"
										scope="row"
										align="center"
									>
										{row.name}
									</StyledTableCell>
									<StyledTableCell sx={{  }} align="center" component="td">
										<Chip
											label={row.employeRate}
											sx={{ borderRadius: '6px', color: Colors.accent1, background: Colors.accent5,
												fontSize: '14px', fontWeight: 500, fontFamily: 'Aspira Demi'
											}}
										/>
									</StyledTableCell>
									<StyledTableCell
										className="is-aspira-600"
										sx={{ fontSize: '16px', fontWeight: 600 }}
										align="center"
										component="td"
									>
										{row.medianSalary}
										{/* $<CounterAnimation value={row.salaryCount} /> */}
									</StyledTableCell>
									<StyledTableCell
										className="is-aspira-600"
										sx={{ fontSize: '16px', fontWeight: 600 }}
										align="center"
										component="td"
									>
										{row.hiredWithIn}
									</StyledTableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</FadeUpAnimation>
			{/* end of table */}
		</Box>
	);
};

export default OutComesSection;
