import { Box, Typography } from '@mui/material';
import React from 'react';

const PageNotFound = () => {
	return (
		<Box sx={{ mt: 20}}>
			<Typography variant="h4" textAlign='center' >Page Not Found</Typography>
		</Box>
	);
};

export default PageNotFound;
