import React from 'react';
import { FormControl, FormHelperText, Input, Typography } from '@mui/material';
import Colors from '../assets/color';

const CustomInput = ({ label = '', value = '', placeholder = '', className, error = false, name, onChange, inputType= "text", errorMsg="Required" }) => {
	return (
		<FormControl fullWidth error sx={{ position: 'relative' }}>
			{label && <Typography sx={{ fontFamily: 'Aspira Bold', mb: 1 }}>{label}</Typography>}
			<Input
				className={className}
				disableUnderline={true}
				placeholder={placeholder}
				name={name}
				value={value}
				onChange={(v) => onChange(v.target.value, name)}
				type={inputType}
				sx={[
					styles.input,
					{
						height: '48px',
						fontFamily: 'Aspira Demi',
						color: Colors.black,
						px: 2,
						fontSize: '18px',
						borderColor: error ? 'danger ' : Colors.accent8,
                        border: error ? '2px solid red' : '2px solid rgba(255, 210, 220, 1)',
						mt: {xs: 4, sm: 4, md: 0}
					}
				]}
			></Input>
			{error && <FormHelperText sx={{ position: 'absolute', bottom: -30, fontSize: 14, fontFamily: 'Aspira' }}>{errorMsg}</FormHelperText>}
		</FormControl>
	);
};

const styles = {
	input: {
		fontWeight: 500,
		// border: '2px solid #40404080',
		borderRadius: '12px',
		'--Input-focusedInset': 'var(--any, )',
		'--Input-focusedThickness': '0.25rem',
		'--Input-focusedHighlight': 'linear-gradient(90deg, rgba(201,42,171,1) 0%, rgba(124,56,231,1) 100%)',
		'&::before': {
			transition: 'box-shadow .15s ease-in-out'
		},
		'&:focus-within': {
		  borderColor: Colors.accent8,
		},
		'& input[type=number]': {
			'-moz-appearance': 'textfield', // Firefox
			'&::-webkit-outer-spin-button': {
			  '-webkit-appearance': 'none', // Chrome, Safari, Edge, Opera
			  margin: 0,
			},
			'&::-webkit-inner-spin-button': {
			  '-webkit-appearance': 'none', // Chrome, Safari, Edge, Opera
			  margin: 0,
			},
		  },
		// '.MuiOutlinedInput-notchedOutline': {
		// 	borderColor: Colors.accent5,
		// 	border: 1
		// },
		// '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
		// 	borderColor: Colors.accent1,
		// 	border: 1
		// },

		// '&:hover .MuiOutlinedInput-notchedOutline': {
		// 	borderColor: 'red'
		// },
		// '.MuiSvgIcon-root ': {
		// 	fill: 'white !important'
		// }
	}
};

export default CustomInput;
