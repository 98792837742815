import { Box, CardMedia, Typography } from '@mui/material';
import Colors from '../assets/color';
import CustomCard from './CustomCard';
import FadeUpAnimation from './framerAnimations/FadeupAnimation';
import Dropdown from './Dropdown';
import { programData } from '../assets/constants';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { howWeWorkScreenContent } from '../assets/screenContent';
import { UserSessionContext } from '../context/UserSessionContext';
import { ChooseProgram, LaunchCareerImg, StartLearningPng } from '../assets/images';

const HowWeWorkSection = () => {
	const { isScreenXl, isScreenLg, isScreenMd, isScreenSm } = useContext(UserSessionContext);
	const [selectedProgram, setSelectedProgram] = useState('');
	const navigate = useNavigate();
	const { firstCardContent, secondCardContent, thirdCardContent } = howWeWorkScreenContent;

	let cardWidth = '80%';

	if (isScreenMd) {
		cardWidth = '100%';
	} else if (isScreenLg) {
		cardWidth = '80%';
	} else if (isScreenXl) {
		cardWidth = '80%';
	}

	const renderCardTextContent = (id, step, title, content, showDropdown = false) => {
		return (
			<Box sx={[styles.textContentContainer, {maxWidth: id <= 2 ? "60%": "50%"}]}>
				<Box>
					<Typography variant="subtitle1" sx={{ fontFamily: 'Aspira Demi' }}>
						{step}
					</Typography>

					<Typography variant="customBoldText" fontSize={'40px'} color={Colors.black}>
						{title}
					</Typography>
					<Typography variant="subtitle2">{content}</Typography>
				</Box>

				{showDropdown && (
					<Dropdown
						data={programData}
						label="Choose Program"
						value={selectedProgram}
						handleChange={e => {
							setSelectedProgram(e.target.value);
                            navigate('/enroll');
						}}
					/>
				)}
			</Box>
		);
	};

	return (
		<>
			{/* First card */}
			<FadeUpAnimation once={true} delayTime={0.4}>
				<CustomCard
					sx={{
						...styles.cardStyle,
						width: cardWidth
					}}
				>
					<Box sx={[styles.innerContent, { flexDirection: isScreenSm ? 'column-reverse' : 'row' }]}>
						{renderCardTextContent(1, firstCardContent.step, firstCardContent.title, firstCardContent.content, true)}
						<CardMedia component="img" sx={[styles.imgStyle, {maxWidth: "40%"}]} src={ChooseProgram} />
					</Box>
				</CustomCard>
			</FadeUpAnimation>

			{/* Second card */}
			<FadeUpAnimation once={true} delayTime={0.4}>
				<Box sx={[styles.rightCardContainer, { justifyContent: isScreenMd ? 'normal' : 'flex-end' }]}>
					<CustomCard
						sx={{
							...styles.cardStyle,
							my: 5,
							width: cardWidth
						}}
					>
						<Box sx={[styles.innerContent, { flexDirection: isScreenSm ? 'column' : 'row' }]}>
							<CardMedia component="img" sx={[styles.imgStyle, {maxWidth: "40%"}]} src={StartLearningPng} />
							{renderCardTextContent(2, secondCardContent.step, secondCardContent.title, secondCardContent.content)}
						</Box>
					</CustomCard>
				</Box>
			</FadeUpAnimation>

			{/* Third card */}
			<FadeUpAnimation once={true} delayTime={0.4}>
				<CustomCard
					sx={{
						...styles.cardStyle,
						width: cardWidth
					}}
				>
					<Box sx={[styles.innerContent, { flexDirection: isScreenSm ? 'column-reverse' : 'row' }]}>
						{renderCardTextContent(3, thirdCardContent.step, thirdCardContent.title, thirdCardContent.content)}
						<CardMedia component="img" sx={[styles.imgStyle, {maxWidth: "50%"}]} src={LaunchCareerImg} />
					</Box>
				</CustomCard>
			</FadeUpAnimation>
		</>
	);
};

const styles = {
	cardStyle: {
		height: '50%',
		padding: '32px',
		boxShadow: '20px 32px 42px 0px #00000014',
		background: Colors.cardTransparentBg
	},
	rightCardContainer: { display: 'flex', my: 4 },
	innerContent: {
		height: '100%',
		display: 'flex'
	},
	textContentContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
		// maxWidth: '60%',
		'@media (max-width: 600px)': {
			maxWidth: '100%'
		}
	},
	imgStyle: {
		objectFit: 'contain',
		// maxWidth: '50%',
		'@media (max-width: 600px)': {
			maxWidth: '100%'
		}
	}
};

export default HowWeWorkSection;
