
import { PythonImage, GolangImage, DevopsImage, 
	FullStackImage, DataEngineer, TechnologyImg, LevarageAIImg, PersonalityImg,  
	CloudComputing,
	BigDataWithHadoop,
	CyberSecurity,
	scala} from '../assets/images';

export const navLinks = [

	{
		title: 'How we work',
		// path: '/how-we-work'
		path: '/#howWeWork'
	},
	{
		title: 'Courses',
		path: '/#courses'
	},
	{
		title: 'Outcomes',
		path: '/#outcomes'
	},
	{
		title: 'Enroll',
		path: '/enroll'
	},
	{
		title: 'About',
		path: '/#aboutUs'
	}
];

export const programData = [
	{
		title: 'Cloud Computing',
	},
	{
		title: 'DevOps & AWS',
	},
	{
		title: 'Data Engineering',
	},
	{
		title: 'Go Programming Language',
	},
	{
		title: 'Cybersecurity',
	},
	{
		title: 'Big Data with Hadoop',
	},
	{
		title: 'Scala Programming',
	},
	{
		title: 'Python Developer Program',
	},
	{
		title: 'Full Stack Developer',
	}
];

export const coursesCardData = [
	{
		image: CloudComputing,
		title: 'Cloud Computing',
		// subTitle: '',
		description: 'Explore cloud computing\'s diverse landscape, including public cloud platforms, private cloud solutions, and hybrid cloud environments to find the best fit for your needs.',
		description2: '',
		bulletPoints: ["Public", "Private", "Hybrid cloud"]
	},
	{
		image: DevopsImage,
		title: 'DevOps & AWS',
		// subTitle: 'Build, Deploy & Scale with Confidence',
		description: 'Become a DevOps Engineer, bridging development and operations. Automate deployments, ensure scalability, and build robust applications on AWS, the leading cloud platform.',
		description2: '',
		bulletPoints: ["Build", "Deploy", "Scale"]
	},
	{
		image: DataEngineer,
		title: 'Data Engineering',
		// subTitle: '',
		description: 'Data Integration. Analytics Engines." - Become a data engineer, an architect building pipelines to move and transform data. Master data integration tools and leverage powerful analytics engines.',
		description2: '',
		bulletPoints: ["Data Integration", "Analytics", "Engines"]
	},
	{
		image: GolangImage,
		title: 'Go Programming Language',
		// subTitle: 'Go Programming Language',
		description: 'Learn Go, a powerful language for building efficient and scalable microservices-based applications, and explore new possibilities beyond traditional programming.',
		description2: '',
		bulletPoints: ["Microservices", "Scalability", "Go Beyond"]
	},
	{
		image: CyberSecurity,
		title: 'Cybersecurity',
		// subTitle: '',
		description: 'Become a cybersecurity expert, safeguarding data and systems through encryption, authentication protocols, and secure network design to ensure robust security.',
		description2: '',
		bulletPoints: ["Encryption", "Authentication", "Secure networks"]
	},
	{
		image: BigDataWithHadoop,
		title: 'Big Data with Hadoop',
		// subTitle: '',
		description: 'Unlock Big Data\'s potential with Hadoop. Master techniques for processing and analyzing massive datasets, building, and managing data lakes and Hadoop clusters.',
		description2: '',
		bulletPoints: ["Data Lakes", "Hadoop Clusters", "Big Data power"]
	},
	// {
	// 	image: HadoopImage,
	// 	title: 'Big Data & Hadoop',
	// 	subTitle: 'Build, Deploy & Scale with Confidence',
	// 	description: 'Become a DevOps Engineer with the commitment level that’s right for you.',
	// 	description2: ''
	// },

	{
		image: scala,
		title: 'Scala Programming',
		// subTitle: '',
		description: 'Dive into Scala, a powerful functional programming language ideal for building scalable Big Data applications with Apache Spark, and achieve Scala mastery.',
		description2: '',
		bulletPoints: ["Functional", "Spark", "Scala mastery"]
	},
	{
		image: PythonImage,
		title: 'Python Developer Program',
		// subTitle: 'Python Developer Program',
		description: 'Master Python, a versatile language used in web development, data science, and machine learning to create innovative solutions and applications',
		description2: '',
		bulletPoints: ["Code", "Execute", "Innovate"]
	},
	{
		image: FullStackImage,
		title: 'Full Stack Developer',
		subTitle: 'Java Full-stack Development ',
		description: 'Become a full-stack Java developer, mastering both front-end and back-end development using the robust and versatile Java programming language.',
		description2: '',
		bulletPoints: ["Frontend", "Backend", "Java synergy"]
	},
];

export const whatMovesOurPillarsData = [
	{
		image: TechnologyImg,
		title: 'Technology',
		description: `At <b>Co-Deploy</b>, We've meticulously analysed market trends and the evolving landscape of technology to ensure our Training and Placement programs 
		are upto date and also ahead of the curve. Our deep dive into industry needs means that when you train with us, you're not 
		just acquiring skills – you're preparing for a thriving career. Our curriculum is crafted to align with the latest demands, 
		ensuring that every candidate is equipped with the knowledge and expertise that top employers are seeking. 
		Join <b>Co-Deploy</b>, where your training today sets the foundation for a successful career tomorrow.`
	},
	{
		image: PersonalityImg,
		title: 'Personality',
		description: `At <b>Co-Deploy</b>, We seamlessly integrate personality development into every curriculum, 
		recognising its critical role in the IT industry. Our programs covers essential topics such as Effective Communication, 
		Leadership skills, Professional conduct, Ethical conduct, Corporate grooming & Super Soft-Skills. These core areas are 
		designed to enhance not just technical proficiency but also the interpersonal skills that are vital for thriving in 
		collaborative, fast-paced environments. By fostering confidence and adaptability, we prepare our candidates to navigate the 
		complexities of the IT world with ease, making them not only valuable team members but also potential leaders in their field.`
	},
	{
		image: LevarageAIImg,
		title: 'Leverage AI',
		description:
			`At <b>Co-Deploy</b>, Empowering our software engineers and developers with the skills to leverage AI, ensuring they stay at the 
			forefront of technological advancements. Our comprehensive training includes understanding AI fundamentals, integrating 
			machine learning algorithms, and utilising AI-driven tools to optimise software development processes. By mastering these 
			techniques, our candidates can create smarter, more efficient applications, automate routine tasks, and drive innovation 
			within their projects. This expertise in AI not only enhances their problem-solving capabilities 
			but also positions them as forward-thinking professionals ready to tackle the challenges of tomorrow's tech landscape.`
	},
	{
		image: PersonalityImg,
		title: 'Interview Prep',
		description:
			`At <b>Co-Deploy</b>, technical expertise is only part of the equation for career success. That’s why we offer robust
			 interview preparation as a key component of our training programs. Our approach includes mock interviews, resume 
			 tailoring, and personalised feedback sessions to ensure our candidates are not only knowledgeable but also 
			 confident and polished when presenting themselves to potential employers. By simulating real-world interview 
			 scenarios and focusing on both technical and behavioural questions, we equip our trainees with the strategies and 
			 skills necessary to make a strong impression and secure their desired positions in the competitive IT industry.`
	}
];


export const usaVisaOptions = ['H1B', 'L1', 'TN', 'US Citizen', 'Any EAD', 'Other'];

export const canadaVisaOptions = ['Open Workpermit', 'Permanent Resident', 'Canada Citizen', 'Other'];

export const getStartedOptions = ['< 2 Weeks', '< a Month', '< 6 Months', '> 6 Months'];


export const outcomesTableData = [
	{
		name: 'Devops Engineer',
		employeRateCount: 92,
		employeRate: '91%',
		salaryCount: 73071,
		medianSalary: '$73,071 - $122,000',
		hiredWithIn: '6 months'
	},
	{
		name: 'Data Engineer',
		employeRateCount: 84,
		employeRate: '84%',
		salaryCount: 78532,
		medianSalary: '$78,532 - $115,000',
		hiredWithIn: '6 months'
	},
	{
		name: 'Python Developer',
		employeRateCount: 62,
		employeRate: '62%',
		salaryCount: 96071,
		medianSalary: '$96,071 - $118,000',
		hiredWithIn: '6 months'
	},
	{
		name: 'Big Data Hadoop',
		employeRateCount: 91,
		employeRate: '91%',
		salaryCount: 75690,
		medianSalary: '$75,690 - $112,000',
		hiredWithIn: '6 months'
	},
	{
		name: 'Golang Developer',
		employeRateCount: 85,
		employeRate: '85%',
		salaryCount: 86200,
		medianSalary: '$86,200 - $128,000',
		hiredWithIn: '6 months'
	}
];

