import React from 'react';
import { AppBar, Box, ButtonBase, Toolbar, useMediaQuery } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { TOP_BAR_HEIGHT } from '../../assets/appLayout';
import NavLinks from './NavLinks';
import CustomDrawer from './CustomDrawer';
import { useCycle } from 'framer-motion';
import navbarStyles from './navbar.module.css';
import AnimatedMenuBtn from '../AnimatedMenuBtn';
import { LogoImg } from '../../assets/images';

const NavBar = () => {
	const location = useLocation();
	const showDrawerIcon = useMediaQuery('(max-width:740px)');

	const navigate = useNavigate();

	const [isDrawerOpen, toggleDrawerOpen] = useCycle(false, true);

	return (
		<>
			<AppBar elevation={0} component="nav" sx={muiStyles.container}>
				<Toolbar disableGutters className="container mx-auto" sx={{ pl: 0, pr: 0, px: {xs: 2} }}>
					<Box sx={muiStyles.innerContainer}>
							<ButtonBase
								disableRipple={true}
								onClick={() => {
									navigate('/#home');
									if (isDrawerOpen) toggleDrawerOpen();
								}}
								className={navbarStyles.logoContainer}
								>
								<img
									srcSet={`${LogoImg}`}
									src={`${LogoImg}`}
									alt="logo"
									loading="lazy"
									className={navbarStyles.logoImg}
								/>
							</ButtonBase>

						{/* Don't show nav links when less than 740px */}
						{!showDrawerIcon && (
							<NavLinks onPressLink={linkPath => navigate(linkPath)} currentRoute={location?.pathname} />
						)}
					</Box>

					{/* Menu icon and Drawer is only visible for screen less than 740px */}
					{showDrawerIcon && (
						<AnimatedMenuBtn
							isOpen={isDrawerOpen}
							onClick={() => toggleDrawerOpen()}
							strokeWidth="4"
							lineProps={{ strokeLinecap: 'round' }}
							transition={{ type: 'spring', stiffness: 260, damping: 20 }}
							// color="#3399cc"
							width={33}
							height={24}
							style={{zIndex: 5}}
						/>
					)}
				</Toolbar>
			</AppBar>


			{/* Menu icon and Drawer is only visible for screen less than 600px */}
			<CustomDrawer
				isDrawerOpen={isDrawerOpen}
				handleDrawerToggle={toggleDrawerOpen}
				onPressLink={linkPath => {
					navigate(linkPath);
				}}
				currentRoute={location.pathname}
			/>
		</>
	);
};

const muiStyles = {
	container: {
		height: TOP_BAR_HEIGHT,
		justifyContent: 'center',
		background: 'transparent',
		zIndex: 5
	},
	innerContainer: {
		display: 'flex',
		flex: 1
	}
};

export default NavBar;
