import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';
import Colors from '../assets/color';

const GradientButton = styled(Button)(({ theme }) => ({
    background: 'linear-gradient(90deg, rgba(177, 217, 243, 1) 0%, rgba(255, 210, 220, 1) 100%)',
    color: Colors.black,
    width: '185px',
    height: '62px',
    fontSize: '16px',
    textTransform: 'none',
    fontWeight: 600,
    fontFamily: 'Aspira',
    borderRadius: '12px',
    paddingTop: 10,
    paddingBottom: 10,
    transition: '0.5s',
    '&:hover': {
      // background: 'linear-gradient(90deg, rgba(110, 72, 232, 1) 0%, rgba(199, 116, 255, 1) 100%)',
      background: 'linear-gradient(90deg, rgba(255, 210, 220, 1) 100%) 0%,rgba(177, 217, 243, 1)',
      color: Colors.black,
    },
    '&.Mui-selected:hover': {
      background: 'linear-gradient(90deg, rgba(255, 210, 220, 1) 100%) 0%,rgba(177, 217, 243, 1)',
      transition: '0.5s ease-in-out',
    },
    '@media (max-width: 576px)': {
      width: '120px',
      height: '42px',
      fontSize: '16px',
    }
  }));
const CustomGradientButton = ({ children, onClick, sx = {}, ...props }) => {
  return (
    <GradientButton sx={sx} {...props} onClick={onClick}>
      {children}
    </GradientButton>
  );
};

export default CustomGradientButton;
