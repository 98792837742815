const Colors = {
	white: '#FFFFFF',
	black: '#000000',
	black2: '#111111',
	gray_200: '#BABABA',
	gray_500: '#667085',
	background: '#fcfcfc',
	backgroundDark: "#333333",
	accent1: '#7544f6',
	accent2: '#4e2da4',
	accent3: '#271752',
	accent4: "#5526CA",
	accent5: '#EEE7FF',
	accent6: '#4B00BC',
	accent7: '#FFFFFFD1',
	accent8: '#AB32FCB2',
	accent9: '#271853',
	background2: '#D6FF7F',
	background3: '#00B3CC',
	background4: '#9055FF',
	background5: '#13E2DA',
	background6: '#2F80ED',
	background7: '#2F80ED',
	background8: '#2F80ED',
	background9: ' #FFCAC9',
	cardTransparentBg: '#FFFFFF4D',
	
	greyText: '#505556',
	grayText2: '#000000A8',
	dividerColor:'#A6A2AF',
	textDark2: '#39434D',
	textDark3: '#000000D1',
	textDark4: '#000000DB',

	borderColor1: '#40404080'
};

export default Colors;
