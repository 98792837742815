import React from 'react';
import { Routes as ApplicationRoutes, Route, useLocation } from 'react-router-dom';
import PageNotFound from './screens/PageNotFound';
import { NavBar } from './components';
import HomeScreen from './screens/HomeScreen';
import Enroll from './screens/EnrollScreen';
import CalendlyCustomButton from './components/CalendlyButton';

const Routes = () => {
	const location = useLocation();

	return (
		<>
				<NavBar />
				<CalendlyCustomButton />
			<ApplicationRoutes location={location} key={location.pathname}>
				<Route path="/" element={<HomeScreen key={'home'} />} />
				<Route path="/enroll" element={<Enroll key={'Enroll'} />} />
				
				<Route path="*" element={<PageNotFound key={'PageNotFound'} />} />
			</ApplicationRoutes>
		</>
	);
};

export default Routes;
