import React from "react";
import { motion } from "framer-motion";

function SlideInAnimation({ text, index, delay=.2, children }) {
  return (
    <motion.div
      className="card"
      initial={{
        opacity: 0,
        x: -50
      }}
      whileInView={{
        opacity: 1,
        x: 0, // Slide in to its original position
        transition: {
          duration: 0.5, // Animation duration
          delay: delay
        },
      }}
      viewport={{ once: true }}
    >
      <p className="card-text">{text}</p>
      {children}
    </motion.div>
  );
}

export default SlideInAnimation;
