import React from 'react';
import { Card } from '@mui/material';

const CustomCard = ({ sx, children, elevation }) => {
	return (
		<Card elevation={elevation}
			sx={[{ boxShadow: 'none', borderRadius: 8 }, sx]}
		>
			{children}
		</Card>
	);
};

export default CustomCard;
