import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';

import Colors from '../../assets/color';
import { coursesCardData } from '../../assets/constants';
import { useNavigate } from 'react-router-dom';
import FadeUpAnimation from '../framerAnimations/FadeupAnimation';

const Item = styled(Paper)(({ theme }) => ({
	background: 'transparent',
	textAlign: 'left',
	fontFamily: 'Aspira',
	color: theme.palette.text.secondary
}));

const InvertedButton = styled(Button)(({ theme }) => ({
	backgroundColor: 'transparent', // Initial background color

	color: theme.palette.primary.accent4, // Text color for contrast
	border: '1px solid #5526CA',
	width: '100%',
	fontSize: '16px',
	textTransform: 'capitalize',
	fontWeight: 600,
	fontFamily: 'Aspira',
	borderRadius: '12px',
	paddingTop: 10,
	paddingBottom: 10,
	boxShadow: 'none',
	'&:hover': {
		// Hover styles
		backgroundColor: theme.palette.primary.accent4, // Inverted background on hover
		color: theme.palette.primary.main // Text color to white on hover
	}
}));

export default function CourseCards() {
	const navigate = useNavigate();

	return (
		<Box elevation={4} sx={{ flexGrow: 1 }} >
			{/* <CustomAccordion
			header={renderHeader()}
			> */}
			<Grid container spacing={{ xs: 2, md: 6 }} columns={{ xs: 4, sm: 12, md: 12 }}>
				{coursesCardData.map((item, index) => (
					// index > 2 ? (

					<Grid
						item
						xs={12}
						sm={6}
						md={4}
						lg={4}
						key={index}
						sx={{ mb: { xs: 10, md: 12 }, pl: 0, transition: '0.2s ease-in' }}
						className="hover:scale-105"
					>
						<FadeUpAnimation key={index} delayTime={index / 10} >
							<Item
								sx={{ borderRadius: 3, maxWidth: 420, background: Colors.cardTransparentBg, boxShadow: '20px 32px 42px 0px #00000014' }}
								key={item.title}
							>
								<Card sx={{ boxShadow: 0, background: 'transparent', p: 2 }}>
									<Box sx={{ minHeight: '338px' }}>
										{item.image && <CardMedia component="img" alt="course img" image={item.image} />}
									</Box>
									<CardContent sx={{ px: 0 }}>
										<Typography
											gutterBottom
											variant="h5"
											component="div"
											textAlign="center"
											sx={{ fontSize: { sm: '16px', md: '20px' } }}
										>
											{item.title}
										</Typography>
										<Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2, justifyContent: 'center' }}>
											{item?.bulletPoints && item.bulletPoints.length
												? item.bulletPoints.map((point, i) => (
													<Typography
														variant="subtitle2"
														sx={{
															display: 'flex',
															justifyContent: 'space-between',
															alignItems: 'center',
															fontFamily: 'Aspira Demi',
															color: Colors.accent4,
															fontSize: "15px"
														}}
													>
														{point}{' '}
														{i !== item.bulletPoints.length-1 && <Box
															sx={{
																height: 6,
																width: 6,
																border: 1,
																borderRadius: 50,
																backgroundColor: Colors.accent4,
																mx: 1
															}}
														></Box>}
													</Typography>
												))
												: null}
										</Box>
										{/* <Typography gutterBottom variant="caption" color="#5526CA" component="div" textAlign="center" 
													sx={{fontFamily: 'Aspira Semibold', minHeight: "20px"}}>
													{item.subTitle}
												</Typography> */}
										<Typography
											variant="subtitle2"
											gutterBottom
											textAlign="center"
											color={Colors.black}
											sx={{ mt: 2, fontFamily: 'Aspira', minHeight: '6.5em', fontSize: "16px" }}
										>
											{item.description}
										</Typography>
										{/* <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2, justifyContent: 'center'}}>
													{item?.bulletPoints && item.bulletPoints.length ? item.bulletPoints.map(point => (
														<Typography variant="caption"
														sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontFamily: 'Aspira'}}
													>
														{point} <Box sx={{ height: 6, width: 6, border: 1, borderRadius: 50, backgroundColor: Colors.accent4, mx: 1 }}></Box>
													</Typography>
													)) : null
													}
												</Box> */}
									</CardContent>
									<CardActions>
										<InvertedButton
											sx={{ fontSize: { xs: '14px', md: '16px' } }}
											onClick={() => {
												setTimeout(() => {
													navigate('/enroll');
												}, 200);
											}}
											variant="contained"
										>
											Enroll
										</InvertedButton>
									</CardActions>
								</Card>
							</Item>
						</FadeUpAnimation>
					</Grid>
					// ) :
					// null
				))}
			</Grid>
			{/* </CustomAccordion> */}
		</Box>
	);
}
