import { Box, ButtonBase, Divider, Typography } from '@mui/material';
import React from 'react';
import { navLinks } from '../../assets/constants';
import Colors from '../../assets/color';

const NavLinks = ({ onPressLink, containerStyle, currentRoute }) => {
	return (
		<Box sx={[styles.container, containerStyle]}>
			{navLinks.map(link => {
				const linkColor = link.path === currentRoute ? Colors.accent2 : Colors.black;
				const showDivider = link.path === '/#aboutUs';

				return (
					<Box key={link?.title} sx={{ display: 'flex' }}>
						{showDivider && (
							<Divider
								color={Colors.dividerColor}
								orientation="vertical"
								flexItem
								sx={{ mx: 2.25, height: '17px', alignSelf: 'center' }}
							/>
						)}

						<ButtonBase
							disableRipple={true}
							key={link?.title}
							onClick={() => onPressLink(link.path)}
							sx={{ ml: showDivider ? 0 : 2.25}}
						>
							<Typography variant="h6" color={linkColor} sx={styles.links}>
								{link.title}
							</Typography>
						</ButtonBase>
					</Box>
				);
			})}
		</Box>
	);
};

const styles = {
	container: {
		display: 'flex',
		flex: 1,
		alignItems: 'center',
		justifyContent: 'flex-end'
	},
	btnStyle: {},
	links: {
		fontFamily: 'Aspira Demi !important',
		transition: 'color 0.5s ease',
		fontSize: '15px',
		'&:hover': {
			color: Colors.accent2
		},
		'@media (max-width: 576px)': {
			fontSize: '16px'
		}
	}
};

export default NavLinks;
