import React, { useState, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import CustomInputWithButton from './CustomInputWithButton';
import { UserSessionContext } from '../context/UserSessionContext.js';
import SlideInAnimation from '../components/framerAnimations/SlideInAnimation';
import Colors from '../assets/color.js';
import { isEmailValid } from '../utils/utils.js';

const SubscribeSection = () => {
	const { addContact, isLoading } = useContext(UserSessionContext);
	const [email, setEmail] = useState('');
	const [isUserSubscribed, setIsUserSubscribed] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');

	const handleChange = event => {
		setErrorMsg('');
		setEmail(event.target.value);
	};

	const handleSubmit = async () => {
		if(!email) {
			setErrorMsg("Please enter your email");
			return;
		}
		if(!isEmailValid(email)){
			setErrorMsg("Please enter a valid email: john@gmail.com");
		}
		else {
			await addContact(email, null, true);
			if (!isLoading) {
				setEmail('');
				setIsUserSubscribed(true);
			}
		}
	};

	return (
		<Box
			className="shadow-sm text-center mx-auto"
			sx={{
				borderRadius: '40px',
				py: 8,
				px: { xs: 3, sm: 7, md: 14 },
				background: 'white',
				width: { xs: '100%', sm: '70%' },
				boxShadow: '20px 32px 42px 0px #00000014'
			}}
		>
			{isUserSubscribed ? (
				<SlideInAnimation>
					<Typography
					textAlign="center"
					variant="customBoldText"
					sx={{ mb: 10, fontSize: { xs: '20px', sm: '30px', md: '48px' } }}
					color="#7544F7"
					>
						Thank you for Subscribing
				</Typography>
				</SlideInAnimation>
			) : (
				<>
					<Typography
						textAlign="center"
						variant="customBoldText"
						sx={{ mb: 10, fontSize: { xs: '20px', sm: '30px', md: '48px' } }}
						color={Colors.black}
					>
						Subscribe to our Newsletter
					</Typography>
					<Box sx={{ mt: 6 }}></Box>
					<CustomInputWithButton
						placeholder="Enter your email"
						value={email}
						onChange={handleChange}
						onSubmit={handleSubmit}
						isLoading={isLoading}
						isError={errorMsg ? true : false}
						errorMsg={errorMsg}
					/>
				</>
			)}
		</Box>
	);
};

export default SubscribeSection;
