import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { navLinks } from '../../assets/constants';
import styles from './drawer.module.css';
import { Box, IconButton } from '@mui/material';
import { CloseIcon, UpRightArrow } from '../../assets/icons';

const CustomDrawer = ({ isDrawerOpen, handleDrawerToggle, onPressLink }) => {
	const menuItemsVariants = {
		closed: { opacity: 0 },
		open: { opacity: 1 }
	};

	const drawerVariant = {
		closed: {
			transition: {
				staggerChildren: 0.2,
				staggerDirection: -1
			}
		},
		open: {
			transition: {
				staggerChildren: 0.2,
				staggerDirection: 1
			}
		}
	};

	return (
		<AnimatePresence>
			{isDrawerOpen && (
				<Box className={styles.container}>
					<motion.aside
						key="aside"
						className={styles.motionContainer}
						initial={{ x: 300, height: '100%' }}
						animate={{ x: 0, transition: { duration: 0.2 } }}
						exit={{ x: 300, transition: { delay: 0.7, duration: 0.3 } }}
					>
						<IconButton 
							style={muiStyles.closeIcon} 
							onClick={handleDrawerToggle}
						>
							<CloseIcon />
						</IconButton>
						<motion.div
							className={styles.drawerContainer}
							initial="closed"
							animate="open"
							exit="closed"
							variants={drawerVariant}
						>
							{navLinks.map(({ title, path }) => {
								return (
									<motion.div
										onClick={() => {
											handleDrawerToggle();
											onPressLink(path);
										}}
										key={title}
										className={styles.itemContainer}
										variants={menuItemsVariants}
									>
										<motion.a
											whileHover={{ scale: 1.05 }}
											style={{ textDecoration: 'none', fontFamily: 'RationalDisplay' }}
										>
											{title}
										</motion.a>

										<UpRightArrow fontSize="small" />
									</motion.div>
								);
							})}
						</motion.div>
					</motion.aside>
				</Box>
			)}
		</AnimatePresence>
	);
};

export default CustomDrawer;

const muiStyles = {
	closeIcon: {
		position: 'absolute',
		top: 35,
		right: 10
	}
};
