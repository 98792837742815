import React from 'react';

const NavigationWrapper = Component =>
	function HOC() {
		return (
			<>
				<Component />

				{/* {!isScreenSm && (
					<motion.div
						className={styles.slideOut}
						initial={{ scaleY: 1 }}
						animate={{ scaleY: 0 }}
						exit={{ scaleY: 0 }}
						transition={{ duration: 2, ease: [0.22, 1, 0.36, 1] }}
					/>
				)} */}
			</>
		);
	};

export default NavigationWrapper;
