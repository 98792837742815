import { ThemeProvider } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import Routes from './Routes';
import theme from './config/theme';
import UserSessionContextProvider from './context/UserSessionContext';
import { BrowserRouter } from 'react-router-dom';

function AppWrap() {
	return (
		<ThemeProvider theme={theme}>
			<UserSessionContextProvider>
				<App />
			</UserSessionContextProvider>
		</ThemeProvider>
	);
}

const App = () => {
	return (
		<BrowserRouter>
			<AnimatePresence>
					<Routes />
			</AnimatePresence>
		</BrowserRouter>
	);
};

export default AppWrap;
