import { Box, Typography } from "@mui/material";
import { color, motion } from 'framer-motion';
import Colors from "../../assets/color";
import AnimatedTextWipe from "../animationText/AnimatedTextLoopWipe";
import Styles from '../../styles/shared/SlideContent.module.css';


const SlideContent = ({title,titleColor=Colors.dark2, animateTextOne, animateTextTwo, animationTextColor=Colors.dark2, containerStyle}) => {
    
    //variants
    const banner = {
        animate: {
            transition: {
                delayChildren: 1,
                staggerChildren: 1
            }
        }
    }
    
    const titleAnimation = {
        initial: {
            y: 400,
            opacity: 0,
        },
        animate: {
            y: [160, 0,],
            opacity: [0.5,1],
            transition: {
                // ease: [0.6, 0.01, -.05, .95],
                duration: 1.5,
            }
        }
    }

    return (
        <motion.div className={Styles.container} style={{containerStyle}} variants={banner}>
            {/* animation wrapper */}
            <motion.div className={Styles.contentWrapper}
                initial={{ scale: [0, 0, 0], borderRadius: ["0", "0"]}}
               
                animate={{
                    scale: [0, 1.01, 0.9, 1.01, 1],
                    // borderRadius: ["150px", "150px", "150px", "35px", "35px"],
                    borderRadius: ["250px", "250px", "250px", "35px", "35px"],
                    type: 'spring'
                  }}
                  transition={{
                    duration: 1.2,
                    ease: "easeInOut",
                    times: [0, 0.3, 0.5, 0.8, 1],
                    delay: .3,
                    // delay: 5
                  }}
             />
                <motion.div 
                    variants={titleAnimation}
                    animate='animate'
                    initial='initial'
                >
                    <Typography variant="h1" sx={{ fontSize: {xs: '2rem', sm: '3rem'}}}  color={titleColor}>
                        {title}
                    </Typography>
                    <motion.div 
                        initial={{
                            opacity: 0, y: 50
                        }}
                        animate={{
                            opacity: 1,
                            y: 0,
                            transition: {
                                ease: 'easeInOut',
                                duration: 1,
                                // delay: 1.2
                                // delay: 6.2
                            }
                        }}
                    >
                        <AnimatedTextWipe
                            animateTextOne={animateTextOne}
                            animateTextTwo={animateTextTwo}
                            type="h1" color={animationTextColor} 
                        />
                    </motion.div>
                </motion.div>
            
		</motion.div>
    );
}

export default SlideContent