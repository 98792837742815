import { FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Colors from '../assets/color';

const Dropdown = ({ data, label = 'select', value = '', handleChange, className }) => {
	return (
		<FormControl fullWidth sx={{pt: 2}}>
			<Select
				className={className}
				value={value}
				onChange={handleChange}
				displayEmpty
				sx={[styles.dropdownContainer, { height: '48px', fontFamily: 'Aspira' }]}
				IconComponent={ExpandMoreIcon}
			>
				{/* Placeholder */}
				<MenuItem value="" sx={{ color: Colors.gray_500 }}>
					{label}
				</MenuItem>

				{data.map(eachTitle => {
					return (
						<MenuItem key={eachTitle.title} value={eachTitle.title} sx={{fontFamily: 'Aspira'}}>
							{eachTitle.title}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};

const styles = {
	dropdownContainer: {
		borderRadius: 4,
		'.MuiOutlinedInput-notchedOutline': {
			borderColor: Colors.black,
			border: 1
		},
		'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: Colors.black,
			border: 1
		}
		// '&:hover .MuiOutlinedInput-notchedOutline': {
		// 	borderColor: 'rgba(228, 219, 233, 0.25)'
		// },
		// '.MuiSvgIcon-root ': {
		// 	fill: 'white !important'
		// }
	}
};

export default Dropdown;
