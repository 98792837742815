import { delay, motion } from 'framer-motion';

const FadeUpAnimation = ({children, once=true, delayTime = 0, offScreenY = 100, onScreenY = 0}) => {

    const cardVariants = {
		offscreen: {
		  y: offScreenY,
		  Opacity: 0
		},
		onscreen: {
		  y: onScreenY,
		  Opacity: 1,
		  transition: {
			type: "spring",
			bounce: 0.4,
			duration: 1,
		  },
		  
		}
	  };

	return (
		<motion.div
			initial="offscreen"
			whileInView="onscreen"
			viewport={{ once: once, amount: delayTime }}
			style={{ width: '100%', height: '100%' }}
		>
			<motion.div variants={cardVariants}>{children}</motion.div>
		</motion.div>
	);
};

export default FadeUpAnimation;
