import React from 'react';
import Marquee from 'react-fast-marquee';
import { Box, Typography } from '@mui/material';
import styles from './Marquee.module.css';
import { motion } from 'framer-motion';
import FadeUpAnimation from '../framerAnimations/FadeupAnimation';

const CustomMarquee = () => {
	const marqueData = Array.from({ length: 100 });

	return (
		<FadeUpAnimation>
			<Marquee speed={100}>
				{marqueData.map((_, index) => (
					<Box key={index} className="flex items-center" sx={{ mr: 10 }}>
						<Typography variant="p" className={styles.title}>
							Co-Deploy
						</Typography>
						{/* <Typography variant="p" sx={{ ml: 3}} className={styles.text}>
              CodeSculpts
            </Typography> */}
					</Box>
				))}
			</Marquee>
		</FadeUpAnimation>
	);
};

export default CustomMarquee;
