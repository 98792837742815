import React from 'react';
import { Box, CardMedia, Grid, Link, List, ListItem, ListItemText, Typography, styled } from '@mui/material';
import {
	InstagramLogo,
	LinkindInLogo,
	LogoImgWithName,
	TwitterLogo,
	WhatsAppLogo
} from '../assets/images';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
	const navigate = useNavigate();
	const FooterListContainer = styled(Box)(({ theme }) => ({
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		padding: theme.spacing(2),
		'@media (max-width: 600px)': {
			alignItems: 'center'
		}
	}));

	const FooterListTitle = styled(Typography)(({ theme }) => ({
		marginBottom: theme.spacing(1),
		fontFamily: 'Aspira Bold',
		fontSize: '32px',
		color: '#FFFFFF !important'
	}));

	const FooterListItem = styled(ListItem)(({ theme }) => ({
		padding: 0,
		marginBottom: theme.spacing(1),
		fontSize: '22px',
		fontFamily: 'Aspira',
		fontWeight: 400,
		color: '#FFFFFFDB',
		cursor: 'pointer',
		'&:hover': {
			color: '#FFFFFF'
		},
		'.MuiTypography-root': {
			color: '#FFFFFFDB !important', // Override the mb
			fontWeight: 400,
			fontFamily: 'Aspira !important',
			'@media (max-width: 600px)': {
				fontSize: '16px'
			}
		}
	}));

	const FooterList = ({ title, items }) => {
		return (
			<FooterListContainer>
				<FooterListTitle variant="h6" sx={{fontSize: {xs: '22px', md: '26px', lg: '32px'}}}>{title}</FooterListTitle>
				<List>
					{items.map((item, index) => (
						<FooterListItem key={index} component="a" className="is-aspira-400" 
						sx={{ fontSize: {xs: '18px', sm: '18px', md: '22px'}}}
						>
							{/* href={`/${item.toLowerCase()}`}  */}
							<ListItemText primary={item.title} onClick={() => item.route && navigate(`/${item.route}`)}/>
						</FooterListItem>
					))}
				</List>
			</FooterListContainer>
		);
	};

	return (
		<Grid container spacing={{ xs: 2, md: 6 }} columns={{ xs: 4, sm: 12, md: 12 }}>
			<Grid item xs={12} sm={3} md={3} lg={3} sx={{ mb: { xs: 5, md: 0 } }} >
				<Box sx={{ width: {xs: '120px', sm: "120px", md: '161px'}, margin: {xs: '0 auto'} }}>
					<CardMedia component="img" alt="course img" width="320px" image={LogoImgWithName} />
				</Box>
			</Grid>
			<Grid item xs={12} sm={3} md={3} lg={3} sx={{ mb: { xs: 6, md: 0 } }}>
				<FooterList title="Company" items={[{title:'About Us', route: '#aboutUs'}, {title: 'Careers', route: ''}, 'Blog']} />
			</Grid>
			<Grid item xs={12} sm={3} md={3} lg={3} sx={{ mb: { xs: 6, md: 0 } }}>
				<FooterList title="Information" items={[{title:'How we work', route: '#howWeWork'}, {title: 'Outcomes', route: '#outcomes'}, 
					{title: 'Train your team', route: ''}, {title: 'Course catalog', route: '#courses'}]} />
			</Grid>
			<Grid item xs={12} sm={3} md={3} lg={3} sx={{ mb: { xs: 6, md: 0 } }}>
				<FooterListContainer>
					<FooterListTitle sx={{fontSize: {xs: '22px', md: '26px', lg: '32px'}, mb: {sm: 2}}}>Follow Us</FooterListTitle>
					<List sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
						<Link
							sx={{ width: {xs: '22px', sm: '24px', md: '30px'} }}
							href="https://www.linkedin.com/company/co-deploy/"
							target="_blank"
							rel=""
						>
							<CardMedia component="img" alt="course img" width="30px" image={LinkindInLogo} />
						</Link>
						<Link
							sx={{ width: {xs: '22px', sm: '24px', md: '30px'} }}
							href="https://www.instagram.com/co_deploy?igsh=MWVkcXBvYjZ3NnJlOQ=="
							target="_blank"
							rel=""
						>
							<CardMedia component="img" alt="course img" width="30px" image={InstagramLogo} />
						</Link>
						<Link
							sx={{ width: {xs: '22px', sm: '24px', md: '30px'} }}
							href="https://x.com/Co_Deploy?t=9lXwpcMCk4CDZHIm3uVOIw&s=09"
							target="_blank"
							rel=""
						>
							<CardMedia component="img" alt="course img" width="30px" image={TwitterLogo} />
						</Link>
					</List>
					<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 4 }}>
						<Link
							sx={{ width: {xs: '22px', sm: '24px', md: '30px'} }}
							href="https://wa.me/919032751179"
							target="_blank"
							rel=""
						>
							<CardMedia component="img" alt="course img" width="30px" image={WhatsAppLogo} />
						</Link>
					</Box>
				</FooterListContainer>
			</Grid>
		</Grid>
	);
};

export default Footer;
