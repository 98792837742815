import React from 'react';
import { TextField, InputAdornment, Button, styled, CircularProgress, FormHelperText, Box } from '@mui/material';
import Colors from '../assets/color';

const CustomButton = styled(Button)(({ theme }) => ({
  color: 'white',
  minWidth: '164px',
  height: '72px',
  fontSize: 18,
  textTransform: 'capitalize',
  fontWeight: 600,
  fontFamily: 'Aspira',
  background: Colors.black,
  borderTopRightRadius: '12px',
  borderBottomRightRadius: '12px',
  transition: 'background .3s ease-in-out, color .3s ease-in-out',
  '&:hover': {
    // background: '#5d37c9',
   background: 'linear-gradient(80deg, rgba(177, 217, 243, 1) 30%, rgba(255, 210, 220, 1) 70%)',
    color: Colors.black
  },
  '@media (max-width: 768px)': {
    minWidth: '64px',
    width: '100px',
    fontSize: '16px'
  },
  '@media (max-width: 576px)': {
    minWidth: '64px',
    width: '80px',
    height: '40px',
    fontSize: '16px'
  }
}));

const CustomInputWithButton = ({ value, onChange, onSubmit, placeholder = "", isLoading=false, isError=false, errorMsg='Required' }) => {
  return (
    <Box sx={{position: 'relative'}}>
    <TextField
      value={value}
      onChange={onChange}
      variant="outlined"
      fullWidth
      placeholder={placeholder}
      error="Please enter your email"
      sx={{
        '& .MuiOutlinedInput-root': {
          paddingRight: 0,
          borderRadius: '12px',
          height: { xs: '42px', sm: '72px' },
          color: '#878C90',
          fontFamily: 'Aspira Demi',
          fontSize: '18px',
          border: 'none',
          boxShadow: '0px 4px 14px rgba(108, 99, 255, 0.18)', // Add drop shadow
          '& fieldset': {
            border: 'none',
          },
        },
        '& .MuiInputBase-input': {
          paddingRight: '33px',
          border: 'none',
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" sx={{ p: 0, m: 0 }}>
            <CustomButton loading={isLoading} onClick={onSubmit}>
            {isLoading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Submit'}
              </CustomButton>
          </InputAdornment>
        ),
      }}
    />
    {isError && <FormHelperText sx={{ position: 'absolute', left: 10, bottom: -30, fontSize: 14, fontFamily: 'Aspira', color: 'red' }}>{errorMsg}</FormHelperText>}
    </Box>
  );
};

export default CustomInputWithButton;
