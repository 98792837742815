import React from 'react';
import CustomGradientButton from './CustomGradientButton';
import { motion } from "framer-motion";

const CalendlyCustomButton = () => {
  const handleCalendlyClick = (event) => {
    event.preventDefault(); // Prevent the default anchor behavior
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        // url: 'https://calendly.com/kranthi-odiseussoftware/30min'
        url: "https://calendly.com/kranthi-odiseussoftware/15mins"
      });
    } else {
      console.error('Calendly is not defined');
    }
    return false;
  };

  return (
    <motion.div initial={{opacity: 0}} animate={{opacity: 1, transition: {delay: 6} }}>
      <CustomGradientButton onClick={handleCalendlyClick} className="bounce-out-on-hover"
            sx={{position: 'fixed', zIndex: 10, borderRadius: '35px', mr: 2, height: 'auto', width: {xs: '200px'}, 
                bottom: 50, right: 10, fontSize: {xs: '14px', sm: '15px', fontFamily: 'Aspira Demi'
                }
            }}>
            Schedule time with us
        </CustomGradientButton>
    </motion.div>
  );
};

export default CalendlyCustomButton;
