import React from 'react';
import { Card, CardContent, Typography, Box, Chip } from '@mui/material';
import Colors from '../../assets/color';

const PillarCard = ({ title, description, scheduleText, hoursText, additionalText }) => {
  return (
    <Card sx={{ 
      boxShadow: '20px 32px 42px 0px #00000014', 
      borderRadius: 8, 
      px: 2, 
    //   background: Colors.white,
    background: Colors.cardTransparentBg,
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <CardContent sx={{ 
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Typography gutterBottom variant="h5" component="div" textAlign="left" fontSize={{ sm: 24 }}
        sx={{
            fontWeight: 700,
            color: Colors.primary,
            borderBottom: `2px solid #e5e7eb`,
            pb: 2
        }}
        >
          {title}
        </Typography>

        <Typography
          className="is-aspira-400"
          gutterBottom
          variant="caption"
          component="div"
          textAlign="left"
          sx={{
            py: 2,
            fontFamily: 'Aspira',
            fontSize: { xs: 14, sm: 16 },
            color: '#000000C2',
            flexGrow: 1, borderBottom: `2px solid #e5e7eb`,
          }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        
        <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2, }}>
          <Typography
            variant="caption"
            fontSize={{xs: 16, sm: 16}}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              fontFamily: 'Aspira Demi'
            }}
            color="#000000"
          >
            {scheduleText}{' '}
            <Box
              sx={{
                height: 6,
                width: 6,
                border: 1,
                borderRadius: 50,
                backgroundColor: Colors.accent4,
                mx: 1
              }}
            />
          </Typography>
          <Typography
            variant="caption"
            fontSize={{xs: 16, sm: 16}}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              fontFamily: 'Aspira Demi'
            }}
          >
            {hoursText}{' '}
            
          </Typography>
        </Box>
      
      </CardContent>
    </Card>
  );
};

export default PillarCard;