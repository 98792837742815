export const howWeWorkScreenContent = {
	firstCardContent: {
		step: 'Step 1:',
		title: 'Choose a Program',
		content: `The first step is to discuss your career goals with
		our Technology Advisor to select the right
		program for you. In addition to technology
		training, our team will mentor you in three other
		essential areas: leveraging Al as a software
		developer or engineer, personality development,
		and interview preparation. You can also choose
		from a range of student-friendly payment options,
		such as paying upfront or on a month-to-month
		basis.`,
		// content:
		// 	"Partner with an Admissions Rep to talk about your career goals and choose the right program for you. Then, choose from a range of student-friendly payment options, such as paying upfront, month to month, or deferring tuition until after you're hired. "
	},
	secondCardContent: {
		step: 'Step 2:',
		title: 'Start Learning',
		content: `The second step on your career path involves
		being guided by a dedicated team and learning the
		skills needed for a tech career. You will receive full
		support from your personal trainer, technology
		advisor, subject matter experts, and have access
		to on-demand help online. This support includes
		rigorous training and thorough preparation for
		both offline and online interviews, with enterprise-
		level access to Pluralsight and Udemy. Candidates
		must commit to a minimum of 25 hours per week
		for 4 months (4 hours a day), choosing to study
		part-time while working or full-time to accelerate
		their career transition.`,
		// content:
		// 	"Learn the skills to break into a new career, with a dedicated team by your side. You'll have the full support of your personal mentor, Academic Success Manager, and expert, on-demand help via live chat. Study part-time while working, or full-time to speed up your career change. "
	},
	thirdCardContent: {
		step: 'Step 3:',
		title: 'Launch a Career',
		content: `The third step is to launch your career.
		After completing your training
		program, you'll receive 3 months of
		dedicated career support. Your
		personal career coach will assist you
		in tailoring your portfolio and resume,
		creating a target employer list,
		excelling in interviews and negotiating
		for the highest salary package.`
	}
};
