import React, { useContext, useEffect, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

import { Backdrop, Box, Button, CircularProgress, Typography, styled, useMediaQuery } from '@mui/material';
import ProgressBar from '@ramonak/react-progress-bar';
import Colors from '../../assets/color';
import CustomInput from '../CustomInput';
import CustomRadioButtonGroup from '../CustomRadioButtonGroup';
import { canadaVisaOptions, getStartedOptions, usaVisaOptions } from '../../assets/constants';
import { isEmailValid } from '../../utils/utils';
import { UserSessionContext } from '../../context/UserSessionContext';

const GradientButton = styled(Button)(({ theme }) => ({
	background: 'linear-gradient(90deg, rgba(199, 116, 255, 1) 0%, rgba(110, 72, 232, 1) 100%)',
	// background: 'linear-gradient(90deg, #5de0e6 0%, #0078a6 100%)',
	color: 'white',
	width: '185px',
	height: '62px',
	fontSize: '20px',
	textTransform: 'capitalize',
	fontWeight: 600,
	fontFamily: 'Aspira',
	borderRadius: '12px',
	paddingTop: 10,
	paddingBottom: 10,
	'&:hover': {
		// backgroundColor: theme.palette.primary.accent4,
		backgroundColor: 'linear-gradient(90deg, rgba(199, 116, 255, 1) 100%, rgba(110, 72, 232, 1) 0%)',
		color: theme.palette.primary.main
	},
	'@media (max-width: 576px)': {
		width: '120px',
		height: '42px',
		fontSize: '16px'
	}
}));

const EnrollSection = () => {
	const { addContact, isLoading } = useContext(UserSessionContext);
	const totalSteps = 6;
	const isScreenSm = useMediaQuery(theme => theme.breakpoints.down('sm'));
	// const initialPercentage =  isScreenSm ? 15 : 10;
	const initialPercentage =  0;
	const stepPercentage = (100 - initialPercentage) / (totalSteps - 1);
	const [activeStep, setActiveStep] = useState(0);
	const [completed, setCompleted] = useState(initialPercentage);
	const [selectedCountry, setSelectedCountry] = useState('');
	const [selectedVisaStatus, setSelectedVisaStatus] = useState('');
	const [selectedGetStated, setSelectedGetStated] = useState('');
	const [token, setToken] = useState(null);
	const [isBackdropVisible, setIsBackdropVisible] = useState(false);

	useEffect(() => {
		setCompleted(initialPercentage)
	  }, [initialPercentage, isScreenSm]);

	const [formData, setFormData] = useState({
		country: '',
	firstName: '',
		middleName: '',
		lastName: '',
		contact: '',
		email: '',
		visaStatus: '',
		getStartedIn: ''
	});

	const [errors, setErrors] = useState({});

	const handleSelection = value => {
		setSelectedCountry(value);
		handleChange('country', value);
	};
	const handleVisaSelection = value => {
		setSelectedVisaStatus(value);
		handleChange('visaStatus', value);
	};

	const handleGetStartedSelection = value => {
		setSelectedGetStated(value);
		handleChange('getStartedIn', value);
		// if(activeStep === 4 && formData.getStartedIn){
			setIsBackdropVisible(true)
		// }
	};

	const handleChange = (name, value) => {
		setFormData(prevData => ({
			...prevData,
			[name]: value
		}));
		setErrors(prevErrors => ({
			...prevErrors,
			[name]: !value
		}));
	};
	

	const handleNext = async () => {
    let newErrors = {};

    if (activeStep === 0 && (!formData.firstName || formData.firstName.length <= 3)) {
        newErrors.firstName = true;
    }
    if (activeStep === 1 && formData.contact.length <= 9) {
        newErrors.contact = true;
    }
    if (activeStep === 1 && !isEmailValid(formData.email)) {
        newErrors.email = true;
    }
    if (activeStep === 2 && !formData.country) {
        newErrors.country = true;
    }
    if (activeStep === 3 && !formData.visaStatus) {
        newErrors.visaStatus = true;
    }
    if (activeStep === 4 && !formData.getStartedIn) {
        newErrors.getStartedIn = true;
    }
	
    if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
    } 
	else {
			
        setErrors({});

        if (activeStep === totalSteps - 2 && token) {
            const fullName = `${formData.firstName ? formData.firstName + ' ' : ''}${formData.middleName ? formData.middleName + ' ' : ''}${formData.lastName ? formData.lastName : ''}`.trim();
            await addContact(fullName, formData);
        }

        const checkLoading = setInterval(() => {
            if (!isLoading) {
                clearInterval(checkLoading);
                setActiveStep(prevStep => {
                    const newStep = prevStep + 1;
                    const newCompleted = initialPercentage + newStep * stepPercentage;
                    setCompleted(newCompleted);
                    return newStep;
                });
            }
        }, 100);

    }
	};

	const handleBack = () => {
		setActiveStep(prevStep => {
			const newStep = prevStep - 1;
			setCompleted(initialPercentage + newStep * stepPercentage);

			// Reset errors related to the current step
			setErrors(prevErrors => {
				const newErrors = { ...prevErrors };
				const stepKey = `step${newStep}`;
				if (stepKey in newErrors) {
					delete newErrors[stepKey];
				}
				return newErrors;
			});

			return newStep;
		});
	};

	const onTokenExpired = async () => {
		await setToken(null);
	}

	const onChange = async (value) => {
			await setToken(value)
	  }

	const getVisaOptions = () => {
		switch (selectedCountry) {
			case 'USA':
				return usaVisaOptions;
			case 'Canada':
				return canadaVisaOptions;
			default:
				return [];
		}
	};


	const renderTitle = () => {
		return (
			<Box>
				<Typography variant="customBoldText" sx={{ fontSize: { xs: '30px', sm: '45px', lg: '60px' } }} color={Colors.black}>
					Enroll Now
				</Typography>
			</Box>
		);
	};

	const renderStep1 = () => {
		return (
			<Box sx={{ my: { xs: 6, sm: 4 } }}>
				<Box
					sx={{
						display: 'flex',
						alignItems: { xs: '', sm: 'center' },
						justifyContent: 'space-between',
						mb: 1,
						flexDirection: { xs: 'column', sm: 'row' }
					}}
				>
					<Typography
						color={Colors.textDark4}
						sx={{ fontFamily: 'Aspira Bold', fontSize: { xs: 16, md: 20, lg: 24 }, width: '50%' }}
					>
						Full Name
					</Typography>
					<Typography color={Colors.grayText2} sx={{ fontFamily: 'Aspira', fontSize: { xs: 12, md: 16 } }}>
						Full name as per your passport*
					</Typography>
				</Box>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						flexDirection: { xs: 'column', md: 'row' }
					}}
				>
					<CustomInput
						className="mr-0 sm:mr-4 mt-2 sm:mt-0"
						placeholder="First Name"
						name="firstName"
						value={formData.firstName}
						onChange={(value, name) => handleChange(name, value)}
						error={errors.firstName}
					/>
					<CustomInput
						className="mr-0 sm:mr-4 mt-2 sm:mt-0"
						placeholder="Middle Name"
						name="middleName"
						value={formData.middleName}
						onChange={(value, name) => handleChange(name, value)}
					/>
					<CustomInput
						className="mr-0 sm:mr-0 mt-2 sm:mt-0"
						placeholder="Last Name"
						name="lastName"
						value={formData.lastName}
						onChange={(value, name) => handleChange(name, value)}
					/>
				</Box>
			</Box>
		);
	};

	const renderStep2 = () => {
		return (
			<Box sx={{ my: { xs: 6, sm: 4 } }}>
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
					<Typography color={Colors.textDark4} sx={{ fontFamily: 'Aspira Bold', fontSize: { xs: 16, md: 26 } }}>
						Contact
					</Typography>
				</Box>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						flexDirection: { xs: 'column', md: 'row' }
					}}
				>
					<CustomInput
						className="mr-0 sm:mr-4 mt-2 sm:mt-0"
						placeholder="Enter contact number"
						name="contact"
						inputType="Number"
						value={formData.contact}
						onChange={(value, name) => handleChange(name, value)}
						error={errors.contact}
						errorMsg={errors.contact ? 'Please enter minimum 10 characters' : ''}
					/>
					<CustomInput
						className="mr-0 sm:mr-4 mt-2 sm:mt-0"
						placeholder="Enter email"
						name="email"
						value={formData.email}
						onChange={(value, name) => handleChange(name, value)}
						error={errors.email}
						errorMsg={errors.email ? 'Please enter a valid email' : ''}
					/>
				</Box>
			</Box>
		);
	};

	const renderStep3 = () => {
		return (
			<Box sx={{ my: { xs: 6, sm: 4 } }}>
				<Typography
					textAlign="center"
					color={Colors.textDark4}
					sx={{ fontFamily: 'Aspira Bold', mb: 1, fontSize: { xs: 16, md: 26 } }}
				>
					Where do you live?
				</Typography>
				<CustomRadioButtonGroup
					name="country"
					selected={selectedCountry}
					onSelected={handleSelection}
					options={['USA', 'Canada']}
				/>
				{errors.country && (
					<Typography textAlign="center" color="red" sx={{ fontSize: 14, mt: 2, fontFamily: 'Aspira' }}>
						Select any country
					</Typography>
				)}
			</Box>
		);
	};

	const renderStep4 = () => {
		return (
			<Box sx={{ my: { xs: 6, sm: 4 } }}>
				<Typography
					textAlign="center"
					color={Colors.textDark4}
					sx={{ fontFamily: 'Aspira Bold', mb: 1, fontSize: { xs: 16, md: 26 } }}
				>
					Visa Status
				</Typography>
				<CustomRadioButtonGroup
					name="visaStatus"
					selected={selectedVisaStatus}
					onSelected={handleVisaSelection}
					options={getVisaOptions()}
				/>
				{errors.visaStatus && (
					<Typography textAlign="center" sx={{ fontSize: 14, mt: 2, fontFamily: 'Aspira' }} color="red">
						Select any option
					</Typography>
				)}
			</Box>
		);
	};

	const renderStep5 = () => {
		return (
			<Box sx={{ my: { xs: 6, sm: 4 } }}>
				<Typography
					textAlign="center"
					color={Colors.textDark4}
					sx={{ fontFamily: 'Aspira Bold', mb: 1, fontSize: { xs: 16, md: 26 } }}
				>
					How soon are you willing to get started with training?
				</Typography>
				<CustomRadioButtonGroup
					name="getStartedIn"
					selected={selectedGetStated}
					onSelected={handleGetStartedSelection}
					options={getStartedOptions}
				/>
				{errors.getStartedIn && (
					<Typography textAlign="center" color="red" sx={{ fontSize: 14, mt: 2, fontFamily: 'Aspira' }}>
						Select any option
					</Typography>
				)}
			</Box>
		);
	};

	return (
		<Box 
			sx={{ position: 'relative', 
			// borderRadius: 40
			borderRadius: 10,
			overflow: 'hidden',
			width: {xs: "100%", sm: "90%"},
			mx: 'auto'
		}}
		 className="shadow-2xl">
			<Box
				sx={{
					position: 'absolute',
					// top: -20,
					top: 2,
					right: 0,
					left: 0,
					zIndex: 1,
					overflow: 'hidden',
					// borderRadius: '40px',
					height: 36,
					// background: 'white'
					background: 'transparent'
				}}
			>
				<ProgressBar
					completed={completed}
					initCompletedOnAnimation={initialPercentage}
					bgColor="linear-gradient(90deg, rgba(201,42,171,1) 0%, rgba(124,56,231,1) 100%)"
					// bgColor="linear-gradient(90deg, #5de0e6 0%, #0078a6 100%)"
					// baseBgColor="white"
					baseBgColor='transparent'
					labelColor="#ffffff"
					customLabel={activeStep <= 3 ? `${activeStep + 1}/${totalSteps}` : `5/5`}
					maxCompleted={100}
					labelAlignment="right"
					height="15px"
					customLabelStyles={{ alignSelf: 'self-start', marginRight: 18, marginTop: -7 }}
				/>
			</Box>
			<Box sx={{ width: '100%', zIndex: 2, position: 'relative',  }}>
				<Box
					sx={{
						background: 'transparent',
						minHeight: {sm: 270, lg: 270},
						zIndex: 2,
						my:8,
						display: activeStep === 5 ? 'flex': 'block', justifyContent: 'center', alignItems: 'center'
					}}
				>
					{activeStep === totalSteps - 1 && !isLoading ? (
						<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%', width: '100%' }}>
							<>
							<Typography
								textAlign="center"
								variant="h4"
								color={Colors.accent2}
								sx={{ mb: 1, fontSize: { xs: 20 }, px: {xs: 4} }}
							>
								Thank you for contacting us
							</Typography>
							<Typography textAlign="center" variant="h4" color={Colors.accent2} sx={{ mb: 1, fontSize: { xs: 20 }, px: {xs: 4} }}>
								Our team will get back to you shortly.
							</Typography>
							</>
						</Box>
					) : (
						<Box sx={{ mx: { xs: 2, sm: 6, lg: 10 }, minHeight: {sm: 270, lg: 270}, }}>
								<Box sx={{ textAlign: 'center', visibility: !activeStep ? 'visible' : 'hidden' }} >{renderTitle()}</Box>
								{activeStep === 0 && renderStep1()}
								{activeStep === 1 && renderStep2()}
								{activeStep === 2 && renderStep3()}
								{activeStep === 3 && renderStep4()}
								{activeStep === 4 && renderStep5()}
								<Box sx={{ display: 'flex', flexDirection: 'row', mt: { xs: 6, md: 10 } }}>
									{activeStep > 0 && (
										<GradientButton onClick={handleBack} sx={{ mx: 2 }}>
											Back
										</GradientButton>
									)}
									<Box sx={{ flex: '1 1 auto' }} />
										
									{activeStep === totalSteps - 2 ? (
											<Box sx={{ mx: 2, display: 'flex'}}>
												<Box sx={{ visibility: token == null ? 'visible': 'hidden'}}>
												{token == null && 
													<Backdrop
														sx={{ color: '#fff' }}
														open={isBackdropVisible}
														// onClick={() => setIsBackdropVisible(false)}
													>
														<Box sx={{
															position: {xs: 'relative', md: 'absolute'}, right: {xs: '0', md: "20%"}, bottom: {xs: "0%", md: "30%"}}}>
														<ReCAPTCHA
															sitekey="6Lch6wQqAAAAAN4U_wtY9N0qf0_2YXDRrgEe2DIK"
															onChange={onChange}
															onExpired={onTokenExpired}
															// type="image"
															type="checkbox"
														/>
													</Box>
														</Backdrop>}
												</Box>
												<GradientButton type='submit' onClick={handleNext} disabled={isLoading}
													sx={{ visibility: token != null ? 'visible': 'hidden'}}
												>
												{isLoading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Submit'}
											</GradientButton>
											</Box>
									) : (
										<GradientButton onClick={handleNext} >Next</GradientButton>
									)}
								</Box>
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default EnrollSection;
