import { createTheme } from '@mui/material';
import colors from '../assets/color';

const theme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			main: colors.background,
			accent1: colors.accent1,
			accent4: colors.accent4,
			button: {
				bgColor: colors.accent4,
				// borderColor: colors.buttonBgColor,
				hoverColor: colors.white
			},
			navBar: {
				topBarBgColor: colors.white
			}
		}
	},
	typography: {
		allVariants: { color: colors.black },
		white: colors.white,
		fontFamily: ['Aspira', 'Aspira Semibold', 'Aspira Bold', 'Aspira Black'],
		h1: {
			fontFamily: 'RationalDisplay ExtraBold, Arial, Verdana, Sans-Serif',
			// fontSize: '8.75rem',
			fontSize: '6.75rem',
			fontStyle: 'normal',
			fontWeight: 700,
			justifyContent: 'center',
			lineHeight: '1em',
			width: '7em',
			'@media (max-width:960px)': {
				fontSize: '48px'
			},
			'@media (max-width:600px)': {
				fontSize: '30px',
				lineHeight: '1.2em',
			}
		},
		h5: {
			fontFamily: 'Aspira Bold, Arial, Verdana, Sans-Serif',
			fontSize: '20px'
		},
		h6: {
			fontFamily: 'Aspira Bold, Arial, Verdana, Sans-Serif',
			fontSize: '17px',
			color: '#505556'
		},
		subtitle2: {
			fontFamily: 'Aspira, Arial, Verdana, Sans-Serif',
			fontSize: '17px',
			color: '#00000099'
		},
		customBoldText: {
			fontFamily: 'Aspira Bold, Arial, Verdana, Sans-Serif',
			fontSize: '20px',
			color: '#505556',
			fontWeight: 700
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				'@font-face': {
					fontFamily: 'Aspira',
				}
			}
		}
	},
	transitions: {
		duration: {
			shortest: 150,
			shorter: 200,
			short: 250,
			// most basic recommended timing
			standard: 300,
			// this is to be used in complex animations
			complex: 375,
			// recommended when something is entering screen
			enteringScreen: 225,
			// recommended when something is leaving screen
			leavingScreen: 195
		},
		easing: {
			// This is the most common easing curve.
			easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
			// Objects enter the screen at full velocity from off-screen and
			// slowly decelerate to a resting point.
			easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
			// Objects leave the screen at full velocity. They do not decelerate when off-screen.
			easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
			// The sharp curve is used by objects that may return to the screen at any time.
			sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
		}
	}
});

export default theme;
