import { Box, Card, CardContent,Chip,Grid, Typography } from '@mui/material';
import Colors from '../../assets/color';
import { whatMovesOurPillarsData } from '../../assets/constants';
import PillarCard from './PillarCard';
import FadeUpAnimation from '../framerAnimations/FadeupAnimation';
import SlideInAnimation from '../framerAnimations/SlideInAnimation';

const AboutUs = () => {

	return (
		<Box>
			<Typography variant="customBoldText" sx={{ fontSize: { xs: '30px', sm: '60px' } }} color={Colors.black}>
				What moves us: Our pillars
			</Typography>
			<Box sx={{ flexGrow: 1, mt: 10 }}>
				<Grid
					container
					spacing={4}
					columns={{ xs: 4, sm: 12, md: 12 }}
					sx={{ justifyContent: { xs: 'center', md: 'flex-start' } }}
				>
					{whatMovesOurPillarsData.map((item, index) => (
						<Grid item xs={12} sm={6} md={6} lg={4} key={index}>
								<PillarCard
									title={item.title}
									description={item.description}
									scheduleText="Set your schedule"
									hoursText="25 Hrs a Week"
								/>
						</Grid>
					))}
				</Grid>
			</Box>
		</Box>
	);
};

export default AboutUs;
