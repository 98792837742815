import React from 'react';
import { Button, Box } from '@mui/material';
import { styled } from '@mui/system';
import Colors from '../assets/color';

const CustomButton = styled(Button)(({ theme }) => ({
  color: '#000000A8',
  minWidth: '180px',
  height: '56px',
  fontSize: 18,
  textTransform: 'capitalize',
  fontWeight: 600,
  fontFamily: 'Aspira',
  borderRadius: '12px',
  border: '2px solid #404040B2',
  // marginRight: '30px',
  background: '#f4f1f8',
  '&.Mui-selected': {
    background: 'linear-gradient(90deg, rgba(199, 116, 255, 1) 0%, rgba(110, 72, 232, 1) 100%)',
    color: 'white',
    border: 'none !important'
  },
  '&:hover': {
    // border: '2px solid #404040B2', // Ensure border is maintained on hover
    background: 'linear-gradient(90deg, rgba(199, 116, 255, 1) 0%, rgba(110, 72, 232, 1) 100%)',
    border: '0',
    color: Colors.white
  },
  '&.Mui-selected:hover': {
    border: '2px solid #404040B2', // Maintain border when selected and hovered
    background: 'linear-gradient(90deg, rgba(199, 116, 255, 1) 100%, rgba(110, 72, 232, 1) 0%)',
    transition: '0.3s ease-in-out'
  },
  '@media (max-width: 576px)': {
		width: '100px',
		height: '40px',
		fontSize: '16px'
	}
}));

const RadioButtonGroup = ({ options, selected, onSelected }) => {
  return (
    <Box textAlign='center' sx={{ display: {xs: 'flex', md: 'block'}, flexDirection: 'column', alignItems: 'center'}}>
      {options.map((option, index) => (
        <CustomButton
          key={index}
          className={selected === option ? 'Mui-selected' : ''}
          onClick={() => onSelected(option)}
          sx={{ mt: {xs: 2, sm: 2, md: 2, lg: 2}, mr: {xs: 0, sm: 4}}}
        >
          {option}
        </CustomButton>
      ))}
    </Box>
  );
};

export default RadioButtonGroup;
