import React from 'react';
import { Typography, Box } from '@mui/material';
import styles from './AnimatedTextLoop.module.css';

const AnimatedTextWipe = ({ color, variant = "h1", animateTextOne, animateTextTwo }) => {

  return (
    <Box className={styles.animationTextContainer}>
      <Box className={styles.wrapper}>
        <Typography className={styles.fromBasics} variant={variant} color={color}>
          {animateTextOne}
        </Typography>
      </Box>
      <Box className={styles.wrapper}>
          <Typography className={styles.second} variant={variant} color={color}>
              {animateTextTwo}
          </Typography>
        </Box>
    </Box>
  );
};

export default AnimatedTextWipe;
