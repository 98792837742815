import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import NavigationWrapper from '../wrapper/NavigationWrapper';
import EnrollSection from '../components/enrolSection/EnrollSection';
import { FullPageBgPNG } from '../assets/images';
import Footer from '../components/Footer';
import Colors from '../assets/color';

const Enroll = () => {

	useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);


	return (
		<>
		
			<Box sx={styles.container}>
				<Box sx={{ width: {xs: "90%", sm: '80%'}}}>

					<EnrollSection />
				</Box>
			</Box>
				{/* Footer */}
				<Box sx={{ background: Colors.black, mt: 0}}>
					<Box className="container mx-auto" sx={{ px: {xs: 2}, py: 4}}>
						<Footer />
					</Box>
				</Box>
				{/* Footer end*/}
		</>
	);
};

const styles = {
	container: {
		backgroundImage: `url(${FullPageBgPNG})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		position: 'relative',
		height: "100vh !important",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	}
}
export default NavigationWrapper(Enroll);
