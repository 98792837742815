import React, { useContext, useEffect, useState } from 'react';
import { motion } from "framer-motion";
import { Box } from '@mui/material';
import Colors from '../assets/color';
import { FullPageBgPNG, LogoImg } from '../assets/images';
import SlideContent from '../components/HomeSlides/SlideContent';
import CustomMarquee from '../components/marquee/CustomMarquee';
import CourseCards from '../components/courseCards/CourseCards';
import OutComesSection from '../components/outComesSection/OutComesSectoin';
import OutcomesBar from '../components/outComesSection/OutcomesBar';
import { useLocation } from 'react-router-dom';
import AboutUs from '../components/whatMovesOurPillars/WhatMovesOurPillars';
import SubscribeSection from '../components/SubscribeSection';
import Footer from '../components/Footer';

import HowWeWorkSection from '../components/howWeWorkSection';
import { UserSessionContext } from '../context/UserSessionContext';

const HomeScreen = () => {
	const { isInitialAnimationVisible, setIsInitialAnimationVisible } = useContext(UserSessionContext);
	const [bgImageLoaded, setBgImageLoaded] = useState(true);
	const [initialScreenDisplay, setInitialScreenDisplay] = useState("");

	useEffect(() => {
		setTimeout(() => {
			setInitialScreenDisplay("none");
			setIsInitialAnimationVisible(false);
		}, 6500);
	})

	useEffect(() => {
		const img = new Image();
		img.src = FullPageBgPNG;
		img.onload = () => setBgImageLoaded(true);
		img.onerror = () => setBgImageLoaded(false);
	}, []);

	const { hash } = useLocation();

	useEffect(() => {
		if (hash) {
			const element = document.getElementById(hash.substring(1));
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			}
		}
	}, [hash]);

	return (
		<Box sx={
			[bgImageLoaded ? styles.container : styles.fallbackContainer,
			{height: initialScreenDisplay == "" && isInitialAnimationVisible ? "100vh" : "auto", 
				overflowY: initialScreenDisplay == "" && isInitialAnimationVisible ? "hidden" : ''}
		]
			}>
			{
			initialScreenDisplay == "" && isInitialAnimationVisible ? 
				<motion.div className="initialAnimationWrapper" 
					initial={{ opacity: 1}}
					animate={{opacity: 0, transition: {delay: 5.5, duration: 0.8}}}
					style={{display: initialScreenDisplay}}
				>
					<motion.div className='LoadinglogoContainer mb-5'
						initial={{
							y:0,
						}}
						animate={{
							scale: [1, 1.1,1,1],
							opacity:[1],
							y: [10,0,10,10]
						  }}
						  transition={{
							duration: 2,
							ease: "easeInOut",
							times: [0, 0.2, 0.5, 0.8, 1],
							repeat: Infinity,
							repeatDelay: 1
						  }}
					>
						<img
								srcSet={`${LogoImg}`}
								src={`${LogoImg}`}
								alt="logo"
								loading="lazy"
								className="logoImg"
							/>
					</motion.div>
					<div className='graduateText'>
						<Box sx={{ xs: "286px", sm: "530px"}}>
							<p className='typewriter'>
								&lt;Innovate.Excel&gt;
								<span class="underline"></span>
								</p>
							
						</Box>
					</div>
				</motion.div>
			:
			<>
				<Box id="home">
				<Box className="container mx-auto" sx={[styles.bannerSection, { mt: { xs: 4, sm: 0 } }]}>
					<SlideContent
						title="Tech Mastery Unleashed"
						animateTextOne="From Basics"
						animateTextTwo="To Brilliance"
						containerStyle={styles.sectionOneTextBox}
					/>
				</Box>
				<Box sx={styles.marqueeContainer}>
					<CustomMarquee />
				</Box>
			</Box>

			<Box id="howWeWork" className="container mx-auto" sx={{ pt: 20, px: { xs: 2 } }}>
				<HowWeWorkSection />
			</Box>
			<Box id="courses" className="container mx-auto" sx={{ pt: 20, px: { xs: 2 } }}>
				<CourseCards />
			</Box>
			{/* on the road to success start */}
			<Box id="outcomes" sx={{ pt: { xs: 15, sm: 15 } }}>
				<Box className="container mx-auto" sx={{ px: { xs: 2 }, mb: 18 }}>
					<OutComesSection />
				</Box>
				<OutcomesBar />
			</Box>
			{/* on the road to success end */}

			{/* what moves out pillars */}
			<Box id="aboutUs" sx={{ pt: 20 }}>
				<Box className="container mx-auto" sx={{ px: { xs: 2 }, mb: 18 }}>
					<AboutUs />
				</Box>
			</Box>
			{/* end of what moves our pillars section */}


			{/* Subscribe start */}
			<Box id="Subscribe" sx={{ py: { xs: 2, sm: 10 }, mb: 30 }}>
				<Box className="container mx-auto" sx={{ px: { xs: 2 } }}>
					<SubscribeSection />
				</Box>
			</Box>
			{/* Subscribe end */}

			{/* Footer */}
			<Box sx={{ background: Colors.black, position: '', bottom: 0, right: 0, left: 0 }}>
				<Box className="container mx-auto" sx={{ px: { xs: 2 }}}>
					<Footer />
				</Box>
			</Box>
			{/* Footer end*/}
			</>
			}
			
		</Box>
	);
};

const styles = {
	container: {
		paddingBottom: 0,
		backgroundImage: `url(${FullPageBgPNG})`,
		backgroundSize: 'cover',
		position: 'relative'
	},
	fallbackContainer: {
		paddingBottom: 10,
		backgroundImage: 'linear-gradient(135deg, #CFDBE4, #FFCED8)',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		position: 'relative'
	},
	cardStyle: {
		height: '50%',
		padding: '32px',
		boxShadow: '20px 32px 42px 0px #00000014',
		background: Colors.cardTransparentBg
	},
	rightCardContainer: { display: 'flex', my: 4 },
	rightCardStyle: {
		my: 10
	},
	innerContent: {
		height: '100%',
		display: 'flex'
	},
	textContentContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
		maxWidth: '50%',
		'@media (max-width: 600px)': {
			maxWidth: '100%'
		}
	},
	imgStyle: {
		objectFit: 'contain',
		maxWidth: '50%',
		'@media (max-width: 600px)': {
			maxWidth: '100%'
		}
	},

	bannerSection: {
		height: '95vh',
		width: '60%',
		margin: '0 auto',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	marqueeContainer: {
		height: '10vh'
	}
};

export default HomeScreen;
