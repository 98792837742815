import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Typography } from '@mui/material';
import Colors from '../../assets/color';
import CounterAnimation from '../framerAnimations/CounterAnimation';
import SlideInAnimation from '../framerAnimations/SlideInAnimation';

const outComeData = [
	{
		id: 1,
		count: 72,
		title: '$72k Minimum',
		description: 'Salary reported by candidates placed by Co-Deploy'
	},
	{
		id: 2,
		count: 4,
		title: '$4.6/5',
		description: 'Career karma rating'
	},
	{
		id: 3,
		count: 92,
		title: '92%',
		description: 'Grads report being hired in 180 days'
	}
];

const renderCounterOne = ({ item }) => {
	if (!item?.id) {
		return null; // or handle this case as needed
	}

	return (
		<div>
			{item.id === 1 && (
				<span>
					$<CounterAnimation value={item.count} />k Minimum
				</span>
			)}
		</div>
	);
};

const renderCounterTwo = ({ item }) => {
	if (!item?.id) {
		return null; // or handle this case as needed
	}

	return (
		<div>
			{item.id === 2 && (
				<>
					<span>
						<CounterAnimation value={4} />.
					</span>
					<span>
						<CounterAnimation value={6} />/
					</span>
					<span>
						<CounterAnimation value={5} />
					</span>
				</>
			)}
		</div>
	);
};

const renderCounterThree = ({ item }) => {
	if (!item?.id) {
		return null; // or handle this case as needed
	}

	return (
		<div>
			{item.id === 3 && (
				<span>
					<CounterAnimation value={item.count} />&#x25;
				</span>
			)}
		</div>
	);
};

const OutcomesBar = () => {
    const [isAtTop, setIsAtTop] = useState(false);
    const ref = useRef(null);
    const backgroundColor = isAtTop ? '#fff' : '#000';
    const textColor = isAtTop ? '#000' : '#fff';

    useEffect(() => {
        const handleScroll = () => {
            if (ref.current) {
                const rect = ref.current.getBoundingClientRect();
                setIsAtTop(rect.top <= 80 && rect.bottom > 0);
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Check initial position

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Box 
            ref={ref}
            sx={{ 
                py: 4, 
                backgroundColor,
                transition: 'background-color 0.3s ease, color 0.3s ease'
            }}
        >
            <Grid container columns={{ xs: 12, sm: 12, md: 12 }}>
                {outComeData.map(item => (
                    <Grid item xs={12} sm={4} md={4} key={item.id}>
                        <Typography
                            className="is-aspira-800"
                            sx={{ 
                                fontSize: { xs: 30, md: 40 }, 
                                mt: { xs: 2, sm: 0 }, 
                                color: textColor,
                                transition: 'color 0.3s ease'
                            }}
                            textAlign="center"
                        >
                            {item.id === 1 && renderCounterOne({ item })}
                            {item.id === 2 && renderCounterTwo({ item })}
                            {item.id === 3 && renderCounterThree({ item })}
                        </Typography>
                        <Typography
                            variant="h6"
                            className="is-aspira-400"
                            textAlign="center"
                            sx={{ 
                                fontSize: { xs: 14, md: 18 }, 
                                color: textColor, 
                                mx: 'auto',
                                transition: 'color 0.3s ease'
                            }}
                        >
                            <SlideInAnimation text={item.description} index={item.id} />
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default OutcomesBar;
