import { useMediaQuery } from '@mui/material';
import { createContext, useState } from 'react';
import { db } from '../config/firebase';
import { addDoc, collection } from 'firebase/firestore';

export const UserSessionContext = createContext();
// const database = db;

const UserSessionContextProvider = ({ children }) => {
	const isScreenXl = useMediaQuery(theme => theme.breakpoints.down('xl'));
	const isScreenLg = useMediaQuery(theme => theme.breakpoints.down('lg'));
	const isScreenMd = useMediaQuery(theme => theme.breakpoints.down('md'));
	const isScreenSm = useMediaQuery(theme => theme.breakpoints.down('sm'));
	const [isLoading, setIsLoading] = useState(false);
	const [isInitialAnimationVisible, setIsInitialAnimationVisible]= useState(true);
	const [selectedCourse, setSelectedCourse] = useState('');
	const contactsCollectionRef = collection(db, 'contacts');

	const addContact = async (name, newContact, isSubscribe = false) => {
		let currentDate = new Date().getTime(),
			id = currentDate.toString();
		
		setIsLoading(true);
		try {
			await addDoc(contactsCollectionRef, {
				id: id,
				to: 'kranthinutse96@gmail.com',
				// to: 'siddu.jash@gmail.com',
				message: 
				isSubscribe ? {
					subject: `${name} has Subscribed for Newsletter`,
					html: `<!DOCTYPE html>
					<html>
					<body>
					  <p>Hi Kranthi,</p>
					
					  <p>You have a new subscriber to the newsletter! </p>
					
					  <p>${name} has just subscribed to receive updates from your website.</p>
					
					  <p>Sincerely,</p>
					
					  <p>Co-Deploy</p>
					</body>
					</html>`
				} 
				: 
				{
					subject: `New Enrollment: ${name} has signed up`,
					html: `
					<!DOCTYPE html>
					<html>
					<head>
						<style>
							table {
								border-collapse: collapse;
								width: 100%;
							}
							th, td {
								border: 1px solid #dddddd;
								text-align: left;
								padding: 8px;
							}
							th {
								background-color: #f2f2f2;
							}
						</style>
					</head>
					<body>
						<h2>Contact Information</h2>
						<table>
							<thead>
								<tr>
									<th>Country</th>
									<th>First Name</th>
									<th>Middle Name</th>
									<th>Last Name</th>
									<th>Contact</th>
									<th>Email</th>
									<th>Visa Status</th>
									<th>Get Started In</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>${newContact?.country}</td>
									<td>${newContact?.firstName}</td>
									<td>${newContact?.middleName}</td>
									<td>${newContact?.lastName}</td>
									<td>${newContact?.contact}</td>
									<td>${newContact?.email}</td>
									<td>${newContact?.visaStatus}</td>
									<td>${newContact?.getStartedIn}</td>
								</tr>
							</tbody>
						</table>
					</body>
					</html>
					`
				}
			  })
		}
		catch(e) {
			console.log(e);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<UserSessionContext.Provider
			value={{ 
				isScreenXl, isScreenLg, isScreenMd, isScreenSm, 
				addContact, isLoading, setIsLoading, isInitialAnimationVisible, setIsInitialAnimationVisible,
				selectedCourse, setSelectedCourse
			}}
		>
			{children}
		</UserSessionContext.Provider>
	);
};

export default UserSessionContextProvider;
